<template>
  <b-form @submit.prevent="save">
    <div class="title-actions">
      <h5 class="title">{{$t('changePassword')}}</h5>
      <div class="btns">
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <div class="form-group">
      <label for="idOrUsername">{{$t('enterIdOrUsername')}}</label>
      <b-form-input v-validate="'required'" name="idOrUsername" id="idOrUsername" v-model="idOrUsername" :state="validateState('idOrUsername')" />
      <span class="error-inputs">{{ errors.first('idOrUsername') }}</span>
    </div>
    <div class="form-group">
      <label for="password">{{$t('selectOption')}}</label>
      <b-form-select v-validate="'required'" name="password" id="password" v-model="password" :state="validateState('password')" :options="options">
      </b-form-select>
      <span class="error-inputs">{{ errors.first('password') }}</span>
    </div>
    <b-button type="submit" variant="primary" :disabled="loading">{{$t('save')}}</b-button>
    <b-button class="ml-3" @click="$router.go(-1)">{{$t('return')}}</b-button>
  </b-form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      idOrUsername: '',
      password: '',
      options: [
        { value: '123456', text: '123456' },
        { value: 'abcdef', text: 'abcdef' },
        { value: 'ABCDEF', text: 'ABCDEF' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang'
    ])
  },
  mounted() {
    this.changeTitleBar(this.$t('changePassword'))
  },
  methods: {
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          const path = `auth/${this.lang}/${this.$route.params.console}/super/change-password`
          const payload = {
            code: this.idOrUsername,
            password: this.password
          }
          this.$axios.post(path, payload).then(() => {
            this.idOrUsername = ''
            this.password = ''
            this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    }
  }
}
</script>
